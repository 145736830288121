// floating-loader.component.ts
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'super-floating-loader',
  template: `
    <div class="loader-overlay"  *ngIf="displayLoader"
      [@fadeInOut]="isLoading ? 'visible' : 'hidden'"
      (@fadeInOut.done)="onAnimationDone($event)"
      [ngStyle]="{ 'background-color': backgroundColor }">
      <container-loader></container-loader>
    </div>
  `,
  styles: [`
    .loader-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 500;
      opacity: 1; /* Ensures opacity is fully set */
      transition: background-color 500ms ease-in-out, opacity 500ms ease-in-out;
    }
  `],
  animations: [
    trigger('fadeInOut', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible => hidden', animate('500ms ease-in-out')),
      transition('hidden => visible', animate('500ms ease-in-out')),
    ]),
  ]
})
export class FloatingLoaderComponent implements OnChanges {
  @Input() isLoading: boolean = false;
  @Input() backgroundColor: string = 'rgba(0, 0, 0, 0.5)'; 

  displayLoader: boolean = false;

  ngOnChanges() {
    if (this.isLoading) {
      this.displayLoader = true; // Show the loader instantly
    }
  }

  onAnimationDone(event: any) {
    if (event.toState === 'hidden') {
      this.displayLoader = false; // Remove loader after fade-out animation
    }
  }
}